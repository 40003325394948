<template>
     <div class="row">
        <div class="col-md-3">
            <!-- Inicio Teléfonos -->
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">{{ $t('general.telefonos') }}</h3>
                    
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-7">
                            <input type="text" v-model="telefonoanyadir" class="form-control form-control-sm" id="telefono-centralita" placeholder="">
                        </div>
                        <div class="col-md-4">
                            <button @click="anyadirTelefono()" class="btn btn-primary btn-sm">{{ $t('general.anadir') }}</button>
                        </div>
                    </div>
                    <br>
                    <table class="table table-sm table-striped">
                        <tbody>
                            <tr v-for="telefono in datoscentralita.telefonos" :key="telefono.telefono">
                                <td width="80%">{{telefono.telefono}}</td>
                                <td><button @click="eliminarTelefono(telefono.id_companyia,telefono.telefono)" type="button" class="btn btn-block btn-outline-danger btn-xs">{{ $t('general.eliminar') }}</button></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- Fin Teléfonos -->
        </div>
        <div class="col-md-3">
            <!-- Inicio rutina -->
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">{{ $t('general.rutina') }}</h3>
                    <div class="card-tools">
                        <button @click="guardarRutina()" type="button" class="btn btn-block btn-default btn-xs">{{ $t('general.guardar') }}</button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <label for="rutina-centralita">{{ $t('general.nombredelarutina') }}</label>
                        <input type="text" v-model="datoscentralita.rutina_centralita" class="form-control form-control-sm" id="rutina-centralita" placeholder="">
                    </div> 
                    <div class="alert alert-default alert-dismissible">
                        <i class="icon fas fa-info"></i>
                    {{ $t('general.descrutina') }}
                    </div>
                </div>
            </div>
            <!-- Fin Rutina -->
        </div>
        <div class="col-md-3">
            <!-- Inicio prefijo -->
            <div class="card card-primary">
                <div class="card-header">
                    <h3 class="card-title">{{ $t('general.prefijo') }}</h3>
                    <div class="card-tools">
                        <button type="button" @click="guardarPrefijo()" class="btn btn-block btn-default btn-xs">{{ $t('general.guardar') }}</button>
                    </div>
                </div>
                <div class="card-body">
                    <div class="form-group">
                        <label for="prefijo-centralita">{{ $t('general.numerodeprefijo') }}</label>
                        <input type="text" v-model="datoscentralita.prefijo_remitente" class="form-control form-control-sm" id="prefijo-centralita" placeholder="">
                    </div> 
                    <div class="alert alert-default alert-dismissible">
                        <i class="icon fas fa-info"></i>
                        {{ $t('general.descprefijo') }}
                    </div>
                </div>
            </div>
            <!-- Fin prefijo -->
        </div>
     </div>

</template>
<script>

import { PwgsApi } from '../../../services/PwgsApi';
export default {
    components: {

        
    },
    props: ['id','datos'],
    data() {
        return {
            datoscentralita : '',
            telefonoanyadir:'',
        }
    },
    methods: {
        async cargarDatos() {
            const api = new PwgsApi();
            this.datoscentralita = await api.get('companias/' + this.$props.id + '/centralita');
            console.log("datoscentralita", this.datoscentralita);
        },
        async eliminarTelefono(idcomp,telefono) {
            const api = new PwgsApi();
            try {
                await api.delete('companias/' + idcomp + '/centralita/' + telefono);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Telefono eliminado correctamente', life: 2000 });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
            this.cargarDatos();
        },
        async anyadirTelefono() {
            const api = new PwgsApi();
            let subidadatos = { telefono: this.telefonoanyadir };
            this.telefonoanyadir = '';

            try {
                await api.post('companias/' + this.$props.id + '/centralita', subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Telefono añadido correctamente', life: 2000 });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
            this.cargarDatos();

            
        },
        async guardarPrefijo() {
            const api = new PwgsApi();
            let subidadatos = { prefijo_remitente: this.datoscentralita.prefijo_remitente };
         
            try {
                await api.put('companias/' + this.$props.id + '/centralita', subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Prefijo guardado correctamente', life: 2000 });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
            this.cargarDatos();


        },
        async guardarRutina() {
            const api = new PwgsApi();
            let subidadatos = { rutina_centralita: this.datoscentralita.rutina_centralita };
            try {
                await api.put('companias/' + this.$props.id + '/centralita', subidadatos);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Rutina guardada correctamente', life: 2000 });
            }
            catch (error) {
                this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
            }
            this.cargarDatos();


        },
        
    },
    watch: {
        id() {
            this.cargarDatos();
        },
        

    },
    mounted() {
        this.cargarDatos();
    },
}
</script>